<template>
    <div
        @click="$emit('backdrop-clicked')"
        class="position-fixed backdrop"
        :style="`top: 0; bottom: 0; left: 0; right: ${backdropRight}; bottom: 0; z-index: 1030; background-color: rgba(39, 42, 51, .3);`"
    >
        <div
            class="menu position-fixed bg-white border-right-secondary py-1"
            :style="`top: 0; left: ${left}; bottom: 0; z-index: 1031; width: ${menuWidth}; transition: left .2s ease-in; overflow-y: auto;`"
        >
            <div class="d-flex flex-column align-items-center mb-5" style="gap: .25rem" @click="redirect()">
                <img src="/assets/img/logo-diri.png" width="56" class="img-fluid" alt="">
                <span class="f-10 text-secondary">Back Office</span>
            </div>
            <ul class="m-0 p-0 d-flex flex-column align-items-center" style="list-style-type: none; gap: 1rem;">
                <li class="" v-for="(data, idx) in menus" :key="idx">
                    <router-link :to="data.url" @click="$emit('item-clicked')">
                        <button class="btn p-0 rounded" :class="{ 'btn-primary': $route.path.startsWith(data.url) }">
                            <img :src="data.icon" alt="" style="width: 40px; margin: 5px;">
                        </button>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { MENUS } from '@/constants/menus';

const WIDTH = 80;

export default {
    props: ['isShowMobile'],
    data() {
        return {
            menuWidth: `${WIDTH}px`,
            left: `-${WIDTH}px`,
            backdropRight: '100%',
        }
    },
    computed: {
        account() {
            return this.$store.state.account;
        },
        permissions() {
            return this.$store.getters.permissions;
        },
        menus() {
            if (this.account === null) {
                return [];
            }

            return MENUS.filter(menu => this.permissions.some(permission => menu.permissions.includes(permission)));
        }
    },
    watch: { 
        isShowMobile: function(isShow) {
            this.left = isShow ? 0 : `-${WIDTH}px`;
            this.backdropRight = isShow ? 0 : '100%';
        }
    },
    methods: {
        redirect() {
            this.$router.push(this.menus[0].link)
        }
    },
}
</script>

<style>
	@media (min-width: 768px) {
		.backdrop {
			right: 100% !important;
		}

        .menu {
            left: 0 !important;
        }
	}
</style>