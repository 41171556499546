<template>
    <header class="sticky-top align-items-center justify-content-between py-1 px-2 bg-white border-bottom-secondary">
        <div class="d-flex align-items-center" style="gap: 1rem;">
            <img v-if="!$route.meta.backButton" src="/assets/img/icon/hamburger-menu-icon.svg" class="d-block d-md-none cursor-pointer" @click="$emit('hamburger-clicked')" alt="" />
            <img v-if="$route.meta.backButton" width="28" height="28" src="/assets/img/icon/arrow-left-icon.svg" class="d-block cursor-pointer" @click="$router.go(-1)" alt="" />
            <p class="f-20 font-500 mb-0">{{ $route.meta.title }} <span class="text-blue" v-html="additional_info"></span></p>
        </div>
        <div v-if="account">
            <a class="d-flex align-items-center" href="#" data-toggle="dropdown">
                <div class="position-relative">
                    <img class="round" style="object-fit: cover" :src="account.admin.avatar ? account.admin.avatar:'/assets/img/icon/profile.png'" alt="avatar" height="40" width="40">
                    <div
                        v-if="isAbleToClockIn"
                        class="indicator-mobile position-absolute d-block d-sm-none"
                        :class="{ 'bg-danger': !isClockedIn, 'bg-success': isClockedIn }"
                        style="top: -2px; right: -2px;"
                    ></div>
                </div>
                <div class="user-nav d-sm-flex align-items-center d-none ml-1 text-right gap-1">
                    <div>
                        <p class="font-600 f-14 text-black mb-0 text-left">{{account.admin.fullName}}</p>
                        <p v-if="isAbleToClockIn" class="f-10 mb-0 d-flex gap-1 align-items-center" style="line-height: 1.25rem;">
                            <span
                                class="indicator"
                                :class="{ 'bg-danger': !isClockedIn, 'bg-success': isClockedIn }"
                            ></span>
                            {{ isClockedIn && subRole ? subRole.name : 'Clocked-out' }}
                        </p>
                    </div>
                    <span class="font-600 f-14 text-black"><i class="fa fa-chevron-down"></i></span>
                </div>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
                <section class="d-lg-none">
                    <div class="col" style="margin-top: 5px;">
                        <p class="text-primary font-600 f-16 mb-0">{{account.admin.fullName}}</p>
                        <p class="text-black f-12 mb-0">{{account.admin.role.name}}</p>
                    </div>
                    <div class="dropdown-divider"></div>
                </section>
                <router-link to="/user/edit_profile" tag="a" class="dropdown-item">
                    <i class="feather icon-user"></i> Ubah Profile
                </router-link>
                <router-link to="/user/change_password" tag="a" class="dropdown-item">
                    <i class="feather icon-lock"></i> Ubah Password
                </router-link>
                <template v-if="isAbleToClockIn">
                    <div class="dropdown-divider"></div>
                    <div class="col d-block d-sm-none" style="margin-top: 5px; padding: 0.25rem 1.5rem;" v-if="subRole">
                        <span class="text-primary font-600 f-14">{{subRole.name}}</span>
                    </div>
                    <span v-if="isClockedIn" class="dropdown-item cursor-pointer" @click="openClockOutModal">Clock-out</span>
                    <span v-else class="dropdown-item cursor-pointer" @click="openClockInModal">Clock-in</span>
                </template>
                <div class="dropdown-divider"></div>
                <a href="javascript:;" @click="logout()" class="dropdown-item">
                    <i class="feather icon-power"></i> Keluar
                </a>
            </div>
        </div>
        <!-- Clock-In Modal -->
        <modal :name="CLOCK_IN_MODAL" :adaptive="true" height="auto" :width="450" style="z-index: 2000;">
            <div class="d-flex flex-column gap-1 h-100 p-2">
                <p class="f-18 font-600 mb-2">What role you're in today?</p>
                <label v-for="role in roles" :key="role.id" :for="role.slug" class="d-flex flex-row align-items-center gap-1">
                    <input type="radio" :id="role.slug" v-model="roleId" :value="role.id">
                    {{ role.name }}
                </label>
                <button class="btn btn-primary mt-3" :disabled="roleId === null || isLoadingClockedIn" @click="clockIn">
                    {{ isLoadingClockedIn ? 'Clocking-in...' : 'Confirm / Clock-in'}}
                </button>
            </div>
        </modal>
    </header>
</template>

<script>

const CLOCK_IN_MODAL = 'clock-in-modal';

export default {
    data() {
        return {
            additional_info: '',
            CLOCK_IN_MODAL,
            roles: [],
            roleId: null,
            isLoadingClockedIn: false,
        }
    },
    computed: {
        account() {
            return this.$store.state.account;
        },
        isClockedIn() {
            return this.$store.getters.isUserClockedIn;
        },
        role() {
            return this.$store.getters.userRole;
        },
        subRole() {
            return this.$store.getters.userSubRole;
        },
        isAbleToClockIn() {
            return this.$store.getters.isAbleToClockIn;
        },
    },
	methods: {
		fullscreen(){
            let elem = document.documentElement
            if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
                if (elem.requestFullScreen) {
                    elem.requestFullScreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullScreen) {
                    elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
                this.isFullScreen = 1
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
                this.isFullScreen = 0
            }
        },
        logout() {
            if (this.isClockedIn) {
                this.$swal.fire({
                    title: 'Konfirmasi Log out',
                    text: 'Kamu akan otomatis terclock-out setelah melakukan logout',
                    icon: 'question',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    confirmButtonColor: '#0036A0',
                    confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        this.$store.dispatch('logout');
                    }
                });
            } else {
                this.$store.dispatch('logout');
            }
        },
        getRoles() {
            this.axios.get('/clinical-role')
                .then(res => {
                    this.roles = res.data.data.clinicalRoles.map(role => ({
                        id: role.id,
                        name: role.name,
                        slug: role.slug,
                    }));
                });
        },
        openClockInModal() {
            this.$modal.show(CLOCK_IN_MODAL);
        },
        clockIn() {
            this.isLoadingClockedIn = true;
            this.axios.post('/admins/clocking', { clinicalRoleId: this.roleId, action: 'clock_in' })
                .then(() => {
                    // close active chatroom
                    this.$router.replace({ query: { chat: undefined } });
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        text: 'You are successfully clocked-in'
                    });
                })
                .catch((err) => {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    });
                })
                .finally(() => {
                    this.isLoadingClockedIn = false;
                    this.$modal.hide(CLOCK_IN_MODAL);
                    this.$store.dispatch('getMe');
                });
        },
        openClockOutModal() {
            this.$swal.fire({
                title: 'Konfirmasi Clock-out',
                text: 'Selama clock-out, kamu tidak bisa membalas chat dari customer',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
            }).then((result) => {
                if (result.value) {
                    this.clockOut();
                }
            });
        },
        clockOut() {
            if (this.subRole?.id) {
                this.axios.post('/admins/clocking', { clinicalRoleId: this.subRole.id, action: 'clock_out', note: 'Manual clock-out' })
                    .then(() => {
                        // close active chatroom
                        this.$router.replace({ query: { chat: undefined } });
                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            text: 'You are successfully clocked-out'
                        });
                    })
                    .catch((err) => {
                        this.$notify({
                            type: 'error',
                            title: 'Error',
                            text: err.response.data.message
                        });
                    })
                    .finally(() => {
                        this.$store.dispatch('getMe');
                    });
            } else {
                this.$notify({
                    type: 'error',
                    title: 'Error',
                    text: 'You are already clocked-out',
                });
            }
        },
	},
    mounted() {
        this.getRoles();
        this.$root.$on('addAdditionalInfo', (data) => {
            this.additional_info = data
        })
    },
}
</script>

<style scoped>
.gap-1 {
  gap: 0.5rem;
}

.indicator {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.indicator-mobile {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
</style>