<template>
	<div class="">
		<Menu
			:is-show-mobile="isMenuOpen"
			@backdrop-clicked="closeMobileMenu"
			@item-clicked="closeMobileMenu"
		/>
		<div class="outer-container">
			<div
				v-if="pusherConnectionMessage.length > 0"
				class="position-absolute bg-warning text-center text-white"
				style="z-index: 10000; left: 0; top: 0; right: 0; padding: 4px;"
			>
				{{ pusherConnectionMessage }}
			</div>
			<Navbar :class="{'d-none': !isNavbarShow, 'd-flex': isNavbarShow, 'd-md-flex': true }" @hamburger-clicked="openMobileMenu" />
			<router-view
				@show-mobile-navbar="showMobileNavbar"
				@hide-mobile-navbar="hideMobileNavbar"
			/>
		</div>
	</div>
</template>

<script>
import Navbar from '@/layouts/components/Navbar'
import Menu from '@/layouts/components/Menu'
import moment from 'moment';
import { setInterval, clearInterval } from 'worker-timers';

export default {
	components: {
		Navbar,
		Menu
	},
	data() {
		return {
			isNavbarShow: true,
			isMenuOpen: false,

			// No activity clock-out modal states
			noActivityClockOutModalInterval: null,
			noActivityClockOutModalTimerInSeconds: 0,
			
			// Operational hours end clock-out modal states
			operationalHoursClockOutModalInterval: null,
			operationalHoursClockOutModalTimerInSeconds: 0,

			// Pusher connection state
			pusherConnectionMessage: '',
		}
	},
	computed: {
		permissions() {
			return this.$store.getters.permissions;
		},
		isClockedIn() {
			return this.$store.getters.isUserClockedIn;
		},
		subRole() {
			return this.$store.getters.userSubRole;
		},
		adminId() {
			return this.$store.getters.adminId;
		},
		shouldSubscribePusher() {
			return this.permissions.includes('consult_prescription:edit');
		},
	},
	watch: {
		adminId: function(adminId) {
			// Handle not subscribe yet on mounted lifecycle
			if (adminId !== null && this.shouldSubscribePusher) {
				const privateChannel = this.$pusher.subscribe('private-clinical');
				const privateIndividualChannel = this.$pusher.subscribe(`private-clinical-${adminId}`);
				this.bindPusherEvents(privateChannel, privateIndividualChannel);
			}
		},
	},
	methods: {
		openMobileMenu() {
			this.isMenuOpen = true;
		},
		closeMobileMenu() {
			this.isMenuOpen = false;
		},
		showMobileNavbar() {
			this.isNavbarShow = true;
		},
		hideMobileNavbar() {
			this.isNavbarShow = false;
		},
		clockOut(note) {
			this.axios.post('/admins/clocking', {
				clinicalRoleId: this.subRole.id,
				action: 'clock_out',
				note,
			})
				.then(() => {
					this.$notify({
						type: 'success',
						title: 'Success',
						text: 'You are successfully clocked-out'
					})
					this.$store.dispatch('getMe');
				})
				.catch(() => {
					this.$store.dispatch('getMe');
				});
		},
		clockOutCountDown(note) {
			this.axios.post('/admins/force-clock-out-countdown', {
				clinicalRoleId: this.subRole.id,
				note,
			})
				.then(() => {
					this.$notify({
						type: 'success',
						title: 'Success',
						text: 'You are successfully clocked-out'
					})
					this.$store.dispatch('getMe');
				})
				.catch(() => {
					this.$store.dispatch('getMe');
				});
		},
		dismissClockOut() {
			this.axios.post('/admins/still-active')
				.then(() => {
					this.$notify({
						type: 'success',
						title: 'Success',
						text: 'You are still clocked-in'
					})
					this.$store.dispatch('getMe');
				})
				.catch(() => {
					this.$notify({
						type: 'error',
						title: 'Error',
						text: 'Failed to keep you clocked-in'
					})
					this.$store.dispatch('getMe');
				});
		},
		bindPusherEvents(privateChannel, privateIndividualChannel) {
			// Private Channel
			privateChannel.bind('OPERATIONAL_HOURS_END', (e) => {
				const { willBeClockedOutAt } = e;
				const clockoutCountdownInSeconds = moment(willBeClockedOutAt).diff(moment(), 'seconds');
				const noClockOutModal = this.operationalHoursClockOutModalInterval === null && this.noActivityClockOutModalInterval === null;
				if (this.isClockedIn && noClockOutModal) {
					if (clockoutCountdownInSeconds > 0) {
						this.operationalHoursClockOutModalTimerInSeconds = clockoutCountdownInSeconds;
						this.$swal.fire({
							title: 'Clock-out Otomatis',
							text: 'Kamu akan terclock-out secara otomatis karena sudah melebihi jam operasional',
							icon: 'question',
							showCancelButton: true,
							cancelButtonText: 'Nanti dulu',
							confirmButtonColor: '#0036A0',
							confirmButtonText: `Ya (${this.operationalHoursClockOutModalTimerInSeconds})`,
							allowOutsideClick: false,
							didOpen: () => {
								this.operationalHoursClockOutModalInterval = setInterval(() => {
									this.operationalHoursClockOutModalTimerInSeconds -= 1;
									this.$swal.update({
										confirmButtonText: `Ya (${this.operationalHoursClockOutModalTimerInSeconds})`,
									});
									if (this.operationalHoursClockOutModalTimerInSeconds <= 0) {
										this.$swal.close();
										this.clockOutCountDown('Clock-out after operational hours');
									}
								}, 1000);
							},
							willClose: () => {
								clearInterval(this.operationalHoursClockOutModalInterval);
								this.operationalHoursClockOutModalInterval = null;
								this.operationalHoursClockOutModalTimerInSeconds = 0;
							},
						}).then((result) => {
							if (result.value) {
								this.clockOut('Clock-out after operational hours');
							} else if (result.dismiss === 'cancel') {
								this.dismissClockOut();
							}
						});
					} else {
						this.$store.dispatch('getMe');
						this.$notify({
							type: 'success',
							title: 'Success',
							text: 'Kamu terclock-out secara otomatis karena sudah melebihi jam operasional'
						});
					}
				}
			});

			// Private Individual Channel
			privateIndividualChannel.bind('NO_ACTIVITY', (e) => {
				const { willBeClockedOutAt, inactiveInMinutes } = e;
				const clockoutCountdownInSeconds = moment(willBeClockedOutAt).diff(moment(), 'seconds');
				const noClockOutModal = this.operationalHoursClockOutModalInterval === null && this.noActivityClockOutModalInterval === null;
				if (this.isClockedIn && noClockOutModal) {
					if (clockoutCountdownInSeconds > 0) {
						this.noActivityClockOutModalTimerInSeconds = clockoutCountdownInSeconds;
						this.$swal.fire({
							title: 'Clock-out Otomatis',
							text: `Tidak ada aktivitas terdeteksi pada ${inactiveInMinutes} menit terakhir. Kamu akan terclock-out secara otomatis`,
							icon: 'question',
							showCancelButton: true,
							cancelButtonText: 'Tidak, saya masih aktif',
							confirmButtonColor: '#0036A0',
							confirmButtonText: `Ya (${this.noActivityClockOutModalTimerInSeconds})`,
							allowOutsideClick: false,
							didOpen: () => {
								this.noActivityClockOutModalInterval = setInterval(() => {
									this.noActivityClockOutModalTimerInSeconds -= 1;
									this.$swal.update({
										confirmButtonText: `Ya (${this.noActivityClockOutModalTimerInSeconds})`,
									});
									if (this.noActivityClockOutModalTimerInSeconds <= 0) {
										this.$swal.close();
										this.clockOutCountDown(`Clock-out after ${inactiveInMinutes} mins inactivity`);
									}
								}, 1000);
							},
							willClose: () => {
								clearInterval(this.noActivityClockOutModalInterval);
								this.noActivityClockOutModalInterval = null;
								this.noActivityClockOutModalTimerInSeconds = 0;
							},
						}).then((result) => {
							if (result.value) {
								this.clockOut(`Clock-out after ${inactiveInMinutes} mins inactivity`);
							} else if (result.dismiss === 'cancel') {
								this.dismissClockOut();
							}
						});
					} else {
						this.$store.dispatch('getMe');
						this.$notify({
							type: 'success',
							title: 'Success',
							text: 'Kamu terclock-out secara otomatis karena tidak ada aktivitas terdeteksi'
						});
					}
				}
			});
			privateIndividualChannel.bind('FORCE_CLOCK_OUT', () => {
				this.$swal.fire({
					title: 'Clock-out Otomatis',
					text: 'Kamu terclock-out secara otomatis oleh administrator',
					icon: 'info',
					confirmButtonColor: '#0036A0',
				});
				this.$store.dispatch('getMe');
			});
			privateIndividualChannel.bind('CLOCK_OUT', () => {
				if (this.isClockedIn) {
					this.$store.dispatch('getMe');
				}
			});
			privateIndividualChannel.bind('CLOCK_IN', () => {
				if (!this.isClockedIn) {
					this.$store.dispatch('getMe');
				}
			});
		},
	},
	mounted() {
		this.$pusher.connection.bind('state_change', (states) => {
			this.$store.dispatch('updatePusherConnectionState', states.current);
			switch (states.current) {
				case 'connected':
					this.pusherConnectionMessage = '';
					setTimeout(() => {
						this.$store.dispatch('getMe');
					}, 1000);
					break;
				case 'unavailable':
				case 'failed':
					this.pusherConnectionMessage = 'There is a connection issue. Please check your internet connection.';
					break;
				default:
					break;
			}
		});
		this.axios.get('/custom-config/auto_assignment')
			.then(res => {
				const { value } = res.data.data;
				this.$store.dispatch('updateConfig', {
						key: 'auto_assignment',
						value,
				});
			});
		
		if (this.adminId !== null && this.shouldSubscribePusher) {
			const privateChannel = this.$pusher.subscribe('private-clinical');
			const privateIndividualChannel = this.$pusher.subscribe(`private-clinical-${this.adminId}`);
			this.bindPusherEvents(privateChannel, privateIndividualChannel);
		}
	},
	beforeDestroy() {
		this.$pusher.allChannels().forEach(channel => {
			this.$pusher.unsubscribe(channel.name);
		});
		// clear auto clock-out modal interval
		if (this.noActivityClockOutModalInterval) {
			clearInterval(this.noActivityClockOutModalInterval);
			this.noActivityClockOutModalInterval = null;
			this.noActivityClockOutModalTimerInSeconds = 0;
		}
		if (this.operationalHoursClockOutModalInterval) {
			clearInterval(this.operationalHoursClockOutModalInterval);
			this.operationalHoursClockOutModalInterval = null;
			this.operationalHoursClockOutModalTimerInSeconds = 0;
		}
	},
}
</script>

<style>
	.outer-container {
		margin-left: 0;
	}

	@media (min-width: 768px) {
		.outer-container {
			margin-left: 80px;
		}
	}
</style>